<template>
  <!-- <Button icon="Epandin" v-if="check"/>
      <Button icon="Epandout" widthIcon="50" heightIcon="50" v-else /> -->
  <div
    class="expand d-flex align-items-center justify-content-center"
    @click="expand()"
  >
    <Button
      classBtnIcon="fill-black"
      :icon="checkExpand ? 'ExpandIn' : 'ExpandOut'"
      width="24"
      height="24"
    />
  </div>
  <!-- <Epandin class="fill-black" /> -->
</template>

<script>
import Button from "@/components/button/button.vue";
export default {
  data() {
    return {
      checkExpand: false,
    };
  },
  components: {
    Button,
  },
  methods: {
    expand() {
      this.checkExpand = !this.checkExpand;
      this.$emit("expand");
    },
  },
};
</script>

<style lang="scss" scoped>
.expand {
  cursor: pointer;
  position: fixed;
  right: 8px;
  top: 440px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $black-400;
  opacity: 0.3 !important;
  transition: 0.3s;
  z-index: 999;
  &:hover {
    opacity: 1 !important;
  }
}
</style>