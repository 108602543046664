
<template>
  <div class="d-flex flex-column align-items-start w-100">
    <!-- General information -->

    <!-- {{ listUserInfo[0].listUserField }} -->
    <template v-for="(item, index) in listUserInfo" :key="index">
      <!-- {{ item }} -->
      <div class="w-100 mb-3 template-group">
        <div
          class="
            expand-head
            d-flex
            justify-content-between
            align-items-center
            w-75
            rounded-1
          "
          :class="device == 'mobile' ? 'w-100' : ''"
        >
          <section
            class="d-flex align-items-center"
            :id="item.intInfogroupID"
            :name="item.strInfoGroupNameEN"
            ref="section"
          >
            <Button
              icon="moveDown"
              classBtnIcon="fill-secondary"
              classBtn="p-0 m-0"
              width="18"
              height="18"
              :class="
                listGroupExpand[index] != undefined
                  ? listGroupExpand[index].blnExpand == false
                    ? 'icon-active'
                    : ''
                  : ''
              "
              @click="
                listGroupExpand[index].blnExpand =
                  !listGroupExpand[index].blnExpand
              "
            />
            <span class="fnt-medium ms-2 fnt-bold fnt-subtitle">
              {{
                $i18n.locale == "en"
                  ? item.strInfoGroupNameEN
                  : item.strInfoGroupNameTH
              }}
            </span>
          </section>
          <!-- <Button icon="RemoveFill" classBtnIcon="fill-black-400" /> -->
        </div>
        <transition name="move">
          <div
            v-if="blnExpand == true"
            class="
              rounded
              d-flex
              align-items-center
              flex-column
              p-3
              justify-content-start
              w-100
              text-black-500
            "
            :class="
              device == 'mobile'
                ? 'p-0 mt-3'
                : 'p-3' && listGroupExpand[index] != undefined
                ? listGroupExpand[index].blnExpand == false
                  ? 'd-none'
                  : ''
                : ''
            "
          >
            <template v-for="(field, index) in item.listUserField" :key="index">
              <div
                v-if="field.intFieldType !== 1 && field.intFieldType !== 4"
                class="w-100"
              >
                <!-- Text -->

                <div
                  v-if="field.strDatatype == 1"
                  class="
                    mb-3
                    d-flex
                    align-items-center
                    justify-content-start
                    w-100
                  "
                >
                  <InputText
                    :label="
                      $i18n.locale == 'en'
                        ? field.strUserFieldNameEN
                        : field.strUserFieldNameTH
                    "
                    :showTxtInp="
                      blnView == true
                        ? true
                        : field.blnEditFlag == 0
                        ? true
                        : false
                    "
                    :value="field.strUserInfoData"
                    @change="
                      (event) => {
                        checkDuplicateUserInfo(
                          field.intUserFieldID,
                          field.intFieldSetID,
                          event.target.value,
                          field.intIdentifier,
                          field.strFieldNameEN
                        );
                      }
                    "
                    class="w-100"
                  />
                </div>
                <!-- Number -->
                <div
                  v-if="field.strDatatype == 2"
                  class="
                    mb-3
                    d-flex
                    align-items-center
                    justify-content-start
                    w-100
                  "
                >
                  <InputText
                    :label="
                      $i18n.locale == 'en'
                        ? field.strUserFieldNameEN
                        : field.strUserFieldNameTH
                    "
                    :showTxtInp="
                      blnView == true
                        ? true
                        : field.blnEditFlag == 0
                        ? true
                        : false
                    "
                    :value="field.strUserInfoData"
                    @change="
                      (event) => {
                        checkDuplicateUserInfo(
                          field.intUserFieldID,
                          field.intFieldSetID,
                          event.target.value,
                          field.intIdentifier,
                          field.strFieldNameEN
                        );
                      }
                    "
                    class="w-100"
                  />
                </div>

                <!-- Currency -->
                <div
                  v-if="field.strDatatype == 3"
                  class="
                    mb-3
                    d-flex
                    align-items-center
                    justify-content-start
                    w-100
                  "
                >
                  <InputText
                    :label="
                      $i18n.locale == 'en'
                        ? field.strUserFieldNameEN
                        : field.strUserFieldNameTH
                    "
                    :value="field.strUserInfoData"
                    :showTxtInp="
                      blnView == true
                        ? true
                        : field.blnEditFlag == 0
                        ? true
                        : false
                    "
                    @change="
                      (event) => {
                        checkDuplicateUserInfo(
                          field.intUserFieldID,
                          field.intFieldSetID,
                          event.target.value,
                          field.intIdentifier,
                          field.strFieldNameEN
                        );
                      }
                    "
                    class="w-100"
                  />
                </div>
                <!-- Time -->

                <div
                  v-if="field.strDatatype == 4"
                  class="
                    mb-3
                    d-flex
                    align-items-center
                    justify-content-start
                    w-100
                  "
                >
                  <TimeInput
                    :label="
                      $i18n.locale == 'en'
                        ? field.strUserFieldNameEN
                        : field.strUserFieldNameTH
                    "
                    :showTxtInp="
                      blnView == true
                        ? true
                        : field.blnEditFlag == 0
                        ? true
                        : false
                    "
                    @change="
                      (event) => {
                        checkDuplicateUserInfo(
                          field.intUserFieldID,
                          field.intFieldSetID,
                          event.target.value,
                          field.intIdentifier,
                          field.strFieldNameEN
                        );
                      }
                    "
                    class="w-100"
                  />
                </div>

                <!-- Date -->
                <div
                  v-if="field.strDatatype == 5"
                  class="
                    mb-3
                    d-flex
                    align-items-center
                    justify-content-start
                    w-100
                  "
                >
                  <DateInput
                    :label="
                      $i18n.locale == 'en'
                        ? field.strUserFieldNameEN
                        : field.strUserFieldNameTH
                    "
                    :showTxtInp="
                      blnView == true
                        ? true
                        : field.blnEditFlag == 0
                        ? true
                        : false
                    "
                    @change="
                      (event) => {
                        checkDuplicateUserInfo(
                          field.intUserFieldID,
                          field.intFieldSetID,
                          event.target.value,
                          field.intIdentifier,
                          field.strFieldNameEN
                        );
                      }
                    "
                    class="w-100"
                  />
                </div>

                <!-- Date & Time -->
                <div
                  v-if="field.strDatatype == 6"
                  class="
                    mb-3
                    d-flex
                    align-items-center
                    justify-content-start
                    w-100
                  "
                >
                  <DateTimeInput
                    :label="
                      $i18n.locale == 'en'
                        ? field.strUserFieldNameEN
                        : field.strUserFieldNameTH
                    "
                    :showTxtInp="
                      blnView == true
                        ? true
                        : field.blnEditFlag == 0
                        ? true
                        : false
                    "
                    @change="
                      (event) => {
                        checkDuplicateUserInfo(
                          field.intUserFieldID,
                          field.intFieldSetID,
                          event.target.value,
                          field.intIdentifier,
                          field.strFieldNameEN
                        );
                      }
                    "
                    class="w-100"
                  />
                </div>

                <!-- Email -->
                <div
                  v-if="field.strDatatype == 7"
                  class="
                    mb-3
                    d-flex
                    align-items-center
                    justify-content-start
                    w-100
                  "
                >
                  <InputText
                    :label="
                      $i18n.locale == 'en'
                        ? field.strUserFieldNameEN
                        : field.strUserFieldNameTH
                    "
                    :showTxtInp="
                      blnView == true
                        ? true
                        : field.blnEditFlag == 0
                        ? true
                        : false
                    "
                    @change="
                      (event) => {
                        checkDuplicateUserInfo(
                          field.intUserFieldID,
                          field.intFieldSetID,
                          event.target.value,
                          field.intIdentifier,
                          field.strFieldNameEN
                        );
                      }
                    "
                    class="w-100"
                  />
                </div>
                <!-- Image -->
                <div
                  v-if="field.strDatatype == 8"
                  class="
                    mb-3
                    d-flex
                    align-items-center
                    justify-content-start
                    w-100
                  "
                >
                  <InputFile
                    alertMsg="419"
                    :ref="field.strFieldNameEN"
                    :name="
                      $i18n.locale == 'en'
                        ? field.strFieldNameEN
                        : field.strFieldNameTH
                    "
                    maxSize="2000000"
                    widthicon="32"
                    heighticon="32"
                    class="w-100"
                    :canClick="
                      blnView == true
                        ? true
                        : field.intFieldType == 2
                        ? true
                        : false
                    "
                    :value="field.strUserInfoImageData + field.strUserInfoData"
                    datavalue="strDataImage"
                    @input="
                      convertToBase64(
                        field.intUserFieldID,
                        field.intFieldSetID,
                        $event
                      )
                    "
                  />
                </div>
              </div>

              <div
                v-if="
                  field.intFieldType === 1 && typeof field.items == 'undefined'
                "
                class="w-100"
              >
                <div
                  class="
                    mb-3
                    d-flex
                    align-items-center
                    justify-content-start
                    w-100
                  "
                >
                  <Select
                    :showTxtInp="
                      blnView == true
                        ? true
                        : field.blnEditFlag == 0
                        ? true
                        : field.intUserFieldID == 7
                        ? true
                        : false
                    "
                    :List="
                      field.strRelation == 'ads_academicorg' ||
                      field.strRelation == 'ads_agency'
                        ? false
                        : true
                    "
                    :Tree="
                      field.strRelation == 'ads_academicorg' ||
                      field.strRelation == 'ads_agency'
                        ? true
                        : false
                    "
                    :blnselected="true"
                    :blnSystemword="false"
                    :strName="
                      $i18n.locale == 'en'
                        ? field.strUserFieldNameEN
                        : field.strUserFieldNameTH
                    "
                    :selectedValue="field.strUserInfoData"
                    @load="getUserfieldTypeControl(index, field.strRelation)"
                    :DropdownItem="listItemsControlField[index]"
                    @input="
                      (event) => {
                        checkDuplicateUserInfo(
                          field.intUserFieldID,
                          field.intFieldSetID,
                          event.value,
                          field.intIdentifier,
                          field.strFieldNameEN
                        );
                      }
                    "
                    class="w-100"
                  />
                </div>
              </div>

              <div
                v-if="
                  field.intFieldType === 1 &&
                  typeof field.items != 'undefined' &&
                  field.strID == 1
                "
                class="w-100"
              >
                <div
                  class="
                    mb-3
                    d-flex
                    align-items-center
                    justify-content-start
                    w-100
                  "
                >
                  <Select
                    :blnAddress="true"
                    size="mb-3"
                    alertMsg="419"
                    :ref="field.strFieldNameEN"
                    :btnRemove="false"
                    :defaultLang="$i18n.locale"
                    :showTxtInp="
                      blnView == true
                        ? true
                        : field.blnEditFlag == 0
                        ? true
                        : field.intUserFieldID == 7
                        ? true
                        : false
                    "
                    :List="true"
                    :blnselected="false"
                    :blnSystemword="false"
                    :strName="
                      $i18n.locale == 'en'
                        ? field.strUserFieldNameEN
                        : field.strUserFieldNameTH
                    "
                    :star="field.intNotNull == 1 ? '*' : ''"
                    :selectedValue="field.items[0].strUserInfoData"
                    @load="getUserfieldAddress(index)"
                    :DropdownItem="listItemsFieldSet[index]"
                    @input="
                      (event) => {
                        listUserFieldSet[index] = event.value;
                        addAddress(
                          field.items,
                          field.intFieldSetID,
                          event.value
                        );
                      }
                    "
                    class="w-100"
                  />
                </div>
              </div>

              <div
                v-if="field.intFieldType === 4 && field.strDataType === 1"
                class="w-100"
              >
                <Select
                  alertMsg="419"
                  :ref="field.strFieldNameEN"
                  :btnRemove="false"
                  :showTxtInp="
                    blnView == true
                      ? true
                      : field.blnEditFlag == 0
                      ? true
                      : field.intUserFieldID == 7
                      ? true
                      : false
                  "
                  :List="true"
                  :blnselected="false"
                  :blnSystemword="false"
                  :strName="
                    $i18n.locale == 'en'
                      ? field.strFieldNameEN
                      : field.strFieldNameTH
                  "
                  :star="field.intNotNull == 1 ? '*' : ''"
                  @load="getUserfieldLookup(index, field.srtID)"
                  :DropdownItem="listItemsLookup[index]"
                  :selectedValue="field.strUserInfoData"
                  @input="
                    (event) => {
                      checkDuplicateUserInfo(
                        field.intUserFieldID,
                        field.intFieldSetID,
                        event.value,
                        field.intIdentifier,
                        field.strFieldNameEN
                      );
                    }
                  "
                  class="w-100"
                />
              </div>

              <div
                v-if="field.intFieldType === 4 && field.strDataType !== 1"
                class="w-100"
              >
                <Select
                  alertMsg="419"
                  :ref="field.strFieldNameEN"
                  :btnRemove="false"
                  :showTxtInp="
                    blnView == true
                      ? true
                      : field.blnEditFlag == 0
                      ? true
                      : field.intUserFieldID == 7
                      ? true
                      : false
                  "
                  :List="true"
                  :blnselected="false"
                  :blnSystemword="false"
                  :strName="
                    $i18n.locale == 'en'
                      ? field.strFieldNameEN
                      : field.strFieldNameTH
                  "
                  :star="field.intNotNull == 1 ? '*' : ''"
                  :selectedValue="field.strUserInfoData"
                  @load="getUserfieldLookup(index, field.srtID)"
                  :DropdownItem="listItemsLookup[index]"
                  @input="
                    (event) => {
                      checkDuplicateUserInfo(
                        field.intUserFieldID,
                        field.intFieldSetID,
                        event.value,
                        field.intIdentifier,
                        field.strFieldNameEN
                      );
                    }
                  "
                  class="w-100"
                />
              </div>
            </template>
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
// import { AddOutline } from "@/components/icon/index";
// import Image from "@/components/thumbnail/thumbnail.vue";
// import { Icon } from "@iconify/vue";

import InputText from "@/components/input/inputText.vue";
import InputFile from "@/components/input/inputFile.vue";
// import SelectDropdown from "@/components/input/selectDropdown.vue";
import Button from "../../button/button.vue";
// import Label from "@/components/label/label.vue";

import urlapi from "@/typescript/urlapi/url";
// import apiuserinfo from "@/typescript/urlapi/adminsystem/APIUserInfo";
import axios from "axios";
import Select from "@/components/input/dropdown/select.vue";
import Enumerable from "linq";
import group from "@/typescript/group/group";
import apiuserinfo from "@/typescript/urlapi/adminsystem/APIUserInfo";
export default {
  components: {
    Button,
    Select,
    // Label,
    // SelectDropdown,
    InputText,
    InputFile,
    // Icon,
    // Image,
    // AddOutline
  },
  emits: ["addUserInfo", "blnActiveEntry"],
  data() {
    return {
      observer: null,
      //รายการ boolean ของ group ที่ expand
      listGroupExpand: [],
      blnExpand: true,
      blnopenform: true,
      blnactiveEntry: false,
      showNote: false,
      listActiveFilters: [],
      listItemsFieldSet: [],
      listItemsControlField: [],
      listItemsLookup: [],
      locales: null,
      listPropotiesUserField: [],
      listUserInfodata: {
        intUserTemplateID: null,
        userInfoData: [],
        // strDataImage:"",
      },
      listSearch: [
        {
          id: 2,
          lang: "138", //Fixed
        },
        {
          id: 3,
          lang: "135", // Variable
        },
        {
          id: 4,
          lang: "137",
          //Lookup
        },
      ],
    };
  },
  beforeMount() {
    // this.listUserInfodata = this.listUserInfo;
    // console.log(this.listUserInfodata);
    //เรียกข้อมูลจาก local
    this.locales = localStorage.getItem("language");
    //ถ้าไม่เจอค่า
    if (this.locales == null || this.locales == "") {
      //ตั้งค่าเริ่มต้นเป็นภาษาอังกฤษ
      this.locales = "en";
      this.$i18n.locale = this.locales;
    }
    //ถ้าเจอข้อมูล
    else {
      //ตั้งค่าภาษาตามข้อมูลที่เรียกได้
      this.$i18n.locale = this.locales;
    }
  },

  props: {
    listUserInfo: { type: Array },
    blnView: { type: Boolean, default: true },
  },
  mounted() {
    console.log(this.listUserInfodata);
    var listexpand = this.listUserInfo;
    for (let i = 0; i < listexpand.length; i++) {
      this.listGroupExpand.push({ blnExpand: true });
    }
    // console.log(this.listGroupExpand);
    setTimeout(() => {
      console.log(this.listUserInfo);

      this.listUserInfo.forEach((item) => {
        item.listUserField.forEach((ele) => {
          this.addUserInfo(
            ele.intUserFieldID,
            ele.intFieldSetID,
            ele.strUserInfoData
          );
        });
      });

      // console.log(this.listUserInfodata.userInfoData);
    }, 500);

    //scroll to active (scrollspy)
    this.initObserver();
    this.observeSections();
  },
  methods: {
    // scroll to active (scrollspy)
    goToSection(sectionName) {
      console.log(sectionName);
      window.scrollTo({
        top: this.$refs.section.filter(
          (e) => e.attributes.name.value === sectionName
        )[0].offsetTop,
        behavior: "smooth",
      });
    },
    observeSections() {
      console.log("observeSections");
      if (this.$refs.section != undefined) {
        this.$refs.section.forEach((section) => {
          this.observe(section);
        });
      }
    },
    observe(entry) {
      this.observer.observe(entry);
    },
    initObserver() {
      console.log("initObserver");
      const options = {
        threshold: [0],
      };
      this.observer = new IntersectionObserver((entries) => {
        const active = entries.filter((e) => e.isIntersecting); //entry.intersectionRatio
        console.log(active);
        if (active.length) {
          console.log(active[0].target.attributes.name.value);
          this.blnactiveEntry = active[0].target.attributes.name.value;
          this.$emit("blnActiveEntry", this.blnactiveEntry);
          // console.log(this.activeEntry)
        }
      }, options);
      console.log(this.observer);
    },

    convertToBase64(_intUserFieldID, _intFieldSetID, file) {
      console.log(file);
      // console.log(file);
      var reader = new FileReader();
      var strDataImage = "";
      new Promise((resolve, reject) => {
        reader.readAsBinaryString(file);
        reader.onload = function (e) {
          var binaryData = e.target.result;
          //Converting Binary Data to base 64
          var base64String = window.btoa(binaryData);
          // console.log(base64String);
          resolve(base64String);
        };
        reader.onerror = function () {
          reject("reader.onerror()");
        };
      })
        .then((resolve) => {
          var data = {};
          data["strData"] = file.name;
          data["strDataImage"] = resolve;
          this.addUserInfo(_intUserFieldID, _intFieldSetID, data, 8);
        })
        .catch(() => {
          var data = {};
          data["strData"] = null;
          data["strDataImage"] = null;
          this.addUserInfo(_intUserFieldID, _intFieldSetID, data, 8);
        });

      // console.log("return")
      return strDataImage;
    },
    onExpand() {
      this.blnExpand = !this.blnExpand;
      this.listGroupExpand.forEach((ele) => {
        ele.blnExpand = this.blnExpand;
      });
    },
    closeFrom() {
      this.blnopenform = !this.blnopenform;
    },
    checked() {
      // console.log("Hello");
    },
    findUserfield(group) {
      var userfield = Object.values(this.listTemplateFrom.field).filter(
        (ele) => {
          return ele.intInfoGroupID === group.intInfoGroupID;
        }
      );
      userfield = Enumerable.from(userfield)
        .orderByDescending((r) => r.intFieldOrder)
        .toArray();

      userfield.reverse();
      // console.log(userfield);
      return userfield;
    },

    // async filter(array, key, _data) {
    //   const getNodes = (result, object) => {
    //     if (Array.isArray(object.listUserField)) {
    //       const listUserField = object.listUserField.reduce(getNodes, []);

    //       if (listUserField.length) {
    //         result.push(listUserField);
    //         return result;
    //       }
    //     }
    //     if (this.filterSelected(object, key, _data)) {
    //       result.push(object);
    //       return result;
    //     }

    //     return result;
    //   };

    //   return Object.values(array).slice().reduce(getNodes, []);
    // },

    filterSelected(item, key, _data) {
      if (item.intUserFieldID == key) {
        item.strUserInfoData = _data;
      }

      // const filterKeys = Object.keys(item);
      // return filterKeys.some((key) => {
      //   console.log(key);
      //   if (key.toString().toLowerCase().indexOf("intHeadingOrder") !== -1) {
      //     //console.log(event);
      //     console.log(item[key]);
      //     return item[key] !== 0;
      //   }
      // });
    },
    addAddress(_intUserFieldID, _intFieldSetID, _data) {
      console.log(_intUserFieldID);
      console.log(_data);
      Object.values(_data.id).forEach((ele, index) => {
        this.listUserInfodata.userInfoData.push({
          intUserFieldID: _intUserFieldID[index].intUserFieldID,
          intFieldSetID: _intFieldSetID,
          strData: ele.toString(),
          strDataImage: "",
        });
      });
      console.log(this.listUserInfodata.userInfoData);
      this.$emit("addUserInfo", this.listUserInfodata.userInfoData);
    },

    async addUserInfo(_intUserFieldID, _intFieldSetID, _data, _strDataType) {
      // console.log(_intUserFieldID, _intFieldSetID, _data);
      // console.log(this.listUserInfodata.userInfoData);
      var item = this.listUserInfodata.userInfoData.filter((e) => {
        return e.intUserFieldID === _intUserFieldID;
      });
      // console.log(item.length);
      console.log(_strDataType);
      console.log(_data);
      console.log(_intUserFieldID);
      if (_data != undefined) {
        if (item.length == 0) {
          if (_strDataType == 8) {
            this.listUserInfodata.userInfoData.push({
              intUserFieldID: _intUserFieldID,
              intFieldSetID: _intFieldSetID,
              strData: _data.strData,
              strDataImage: _data.strDataImage,
            });
          } else {
            this.listUserInfodata.userInfoData.push({
              intUserFieldID: _intUserFieldID,
              intFieldSetID: _intFieldSetID,
              strData: _data.toString(),
              strDataImage: "",
            });
          }
        } else if (item.length != 0) {
          this.listUserInfodata.userInfoData.forEach((e, index) => {
            console.log(e.intUserFieldID);
            if (e.intUserFieldID === _intUserFieldID) {
              if (_strDataType == 8) {
                this.listUserInfodata.userInfoData[index].strData =
                  _data.strData;
                this.listUserInfodata.userInfoData[index].strDataImage =
                  _data.strDataImage;
              } else
                this.listUserInfodata.userInfoData[index].strData =
                  _data.toString();
            }
          });
        }

        this.$emit("addUserInfo", this.listUserInfodata.userInfoData);
      }
      // console.log(this.listUserInfodata.userInfoData);
    },

    async checkDuplicateUserInfo(
      _intUserFieldID,
      _intFieldSetID,
      _data,
      _intIdentifier,
      refName
    ) {
      console.log(_data);
      try {
        // console.log(this.listUserInfodata);
        // console.log(_data);
        if (_intIdentifier == 1) {
          var fieldData = {
            intFieldID: _intUserFieldID,
            strFieldValue: _data,
          };

          // console.log(fieldData);
          var res = await axios.post(
            urlapi + apiuserinfo.CheckDuplicateUserInfo,
            fieldData
          );
          console.log(res.data);

          if (res.data.message === "Duplicate") {
            this.$refs[refName][0].alertDataDuplicate("221");
            this.listPropotiesUserField.push({
              intUserFieldID: _intUserFieldID,
              Duplicate: true,
            });
          } else {
            console.log("add userinfo 1");
            this.listPropotiesUserField = this.listPropotiesUserField.filter(
              (ele) => {
                return ele.intUserFieldID != _intUserFieldID;
              }
            );
            this.addUserInfo(_intUserFieldID, _intFieldSetID, _data);
          }
        } else {
          console.log("add userinfo 2");
          this.addUserInfo(_intUserFieldID, _intFieldSetID, _data);
        }
      } catch (error) {
        console.log(error);
      }
    },

    //ดึงข้อมูล userfield แบบ control
    async getUserfieldTypeControl(index, strRelation) {
      // console.log(strRelation);
      try {
        if (this.listItemsControlField[index] == undefined) {
          var res = await axios.get(
            urlapi +
              "/v1/api/AdsUserTemplate/GetControlFieldData/" +
              strRelation
          );
          if (
            strRelation === "ads_agency" ||
            strRelation === "ads_academicorg"
          ) {
            setTimeout(() => {
              this.listItemsControlField[index] = group.unflatten(
                { ...res.data.data },
                strRelation === "ads_academicorg"
                  ? "intParentacorg"
                  : "intAgencyParent"
              );
              Object.values(this.listItemsControlField[index]).forEach((e) => {
                // e.push = { blnDrop: true };
                e.blnDrop = false;
              });
              // console.log(this.listItemsControlField[index]);
            }, 1000);
          } else {
            this.listItemsControlField[index] = { ...res.data.data };
          }
          // console.log(this.listItemsControlField[index]);
        }
      } catch (error) {
        console.lob(error);
      }
    },

    //ดึงข้อมูล userfield แบบ control address
    getUserfieldAddress(index) {
      try {
        if (this.listItemsFieldSet[index] == undefined) {
          //ดึงข้อมูล userfield แบบ control address
          this.fetchUserfiledSetAddress().then((data) => {
            //console.log(data);
            this.listItemsFieldSet[index] = { ...data };
            // console.log(this.listItemsFieldSet);
          });
        }
        // return this.listItemsFieldSet[intBelongData + 1];
      } catch (error) {
        console.log(error);
      }
      //return items;
    },

    //ดึงข้อมูล userfield แบบ control address
    async fetchUserfiledSetAddress() {
      // console.log(strRelation);
      try {
        // console.log(this.listUserFieldSet);
        let res = await axios.get(
          urlapi + "/v1/api/AdsUserField/GetAddressList/"
        );

        let data = [];
        data = { ...res.data.data };
        // console.log(data);

        return data;
      } catch (error) {
        console.log(error);
      }
    },

    //ดึงข้อมูล userfield แบบ lookup
    async getUserfieldLookup(index, UserFieldID) {
      try {
        if (this.listItemsLookup[index] == undefined) {
          var res = await axios.get(
            urlapi + "/v1/api/AdsUserTemplate/LookupList/" + UserFieldID
          );
          this.listItemsLookup[index] = { ...JSON.parse(res.data.data) };
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropzoned {
  border: 2px $black-500;
  border-style: dashed;
}
.template-group {
  &:hover {
    .expand-head {
      background-color: $black-200;
      cursor: pointer;
    }
    .dropzoned {
      border: 2.5px $primary dashed !important;
    }
  }
}
.field-img {
  .img {
    width: 174px;
    height: 120px;
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.group-img {
  .img {
    width: 176px;
    height: 124px;
    position: relative;
  }
}
.icon-active {
  transform: rotate(180deg) !important ;
}

.move-enter-active {
  animation: move-in 0.3s;
}
.move-leave-active {
  animation: move-in 0.3s reverse;
}
@keyframes move-in {
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
