<template>
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
  />
  <AltStatus
    :blnalert="blnAltStatusunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
  />
  <AltStatus
    :blnalert="blnAltLoadingData"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('223')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
  />
  <div class="p-2 rounded-3 shadow-sm">
    <div
      class="
        mt-2
        pt-2
        heading
        rounded-2
        w-100
        d-flex
        flex-column
        align-items-start
        justify-content-start
      "
    >
      <div class="d-flex flex-row mb-3">
        <div
          class="
            profile
            rounded-2
            d-flex
            align-items-center
            justify-content-center
            me-3
          "
        >
          <!-- Heading รูปภาพ -->
          <div
            class="img"
            :class="
              this.listImage.strUserInfoImageData == '' ||
              this.listImage.strUserInfoImageData == undefined ||
              this.listImage.strUserInfoImageData == null
                ? 'd-none'
                : ''
            "
          >
            <Avatar
              :image="
                this.listImage.strUserInfoImageData +
                this.listImage.strUserInfoData
              "
              :usertype="
                usertype == 'Student'
                  ? 'student'
                  : '' || usertype == 'Officer'
                  ? 'officer'
                  : ''
              "
            />
          </div>
        </div>

        <!-- ข้อมูลของ User (จะเปลี่ยนตามการพิมพ์) -->
        <div
          v-if="blnHaveHeadling == true"
          class="d-flex align-items-start justify-content-center flex-column"
        >
          <div
            class="
              d-flex
              flex-wrap
              template-active
              align-items-center
              rounded-2
              mb-1
            "
          >
            <span class="fnt-medium fnt-h5 me-1">{{ findHeading(1) }}</span>
          </div>
          <div
            class="heading-list mb-1 w-100 rounded-2 d-flex align-items-center"
          >
            <span class="fnt-regular fnt-h6 me-1">{{ findHeading(2) }}</span>
          </div>
          <div
            class="heading-list mb-1 w-100 rounded-2 d-flex align-items-center"
          >
            <span class="fnt-regular fnt-body me-1">{{ findHeading(3) }}</span>
          </div>
          <!-- {{ findHeading(1) }} -->
          <!-- <template v-for="(item, index) in listSortData[0]" :key="index"> -->
          <!-- <template v-for="(item, index) in findHeading(1)" :key="index">
        <div
          class="
            d-flex
            flex-wrap
            template-active
            align-items-center
            rounded-2
            mb-1
          "
        >
          <span class="fnt-medium fnt-h5 me-1">{{ item.strUserInfoData }}</span>
        </div>
      </template> -->
        </div>
      </div>

      <!-- เส้นขั้นระหว่าง Heading กับ Template ที่จะกรอก -->
      <hr class="w-100 m-0 mb-3" />
    </div>

    <div class="overlay-box" v-if="overlay">
      <div
        class="overlay-alertpop"
        @click="(overlay = !overlay), (blnAltHavedata = false)"
      >
        <AlertPopup
          v-if="blnAltHavedata == true"
          :altMsgBox="$t('216')"
          class="warning"
          classOkBtn="btn-warning text-white"
          classCancelBtn="text-warning"
          artIcon="WarningOutline"
          @confirm="confirmAltPopup($event)"
          classIcon="text-secondary"
          :strButtonSubmit="'218'"
        />
      </div>
    </div>
    <div class="row m-0 p-0 form">
      <div class="col-xxl-8 col-xs-10 col-md-10 col-sm-10 w-100">
        <div
          class="
            d-flex
            flex-column
            align-items-start
            h-100
            p-5
            position-relative
          "
        >
          <div :class="blnShowform == false ? '' : 'd-none'" class="w-100">
            <!-- ส่วนของการเลือก User type และ Template -->
            <!-- เลือก User type ก่อน ถึงจะเลือก Template ได้ -->
            <!-- <Label label="User information" class="mb-3" /> -->
            <!-- <div class="row mb-3 w-100"> -->
            <!-- <div class="col-6">
              <Dropdown
                strName="User type"
                :listDataSelection="listUserType"
                @selected="fetchTemplate($event)"
              />
            </div> -->
            <!-- User type -->
            <!-- <div class="col-6">
              <Dropdown strName="Template" :listDataSelection="listTemplate" />
            </div> -->
            <!-- Template -->
            <!-- </div> -->
            <!-- <div class="d-flex mt-5"> -->
            <!-- <Button name="OK" class="me-3 btn-primary btn-fix" /> -->
            <!-- ปุ่ม OK -->
            <!-- <Button name="Cancel" class="btn-cancel btn-fix" /> -->
            <!-- ปุ่ม Cancel -->
            <!-- </div> -->
            <!-- Label form -->
          </div>
          <div class="w-100">
            <Expand @expand="expand()" />
            <!-- ปุ่ม Expand all -->
            <!-- ส่วนของ Heading -->

            <!-- เส้นขั้นระหว่าง Heading กับ Template ที่จะกรอก -->
            <!-- <hr class="w-100 m-0 mb-3" /> -->
            <!-- Form ที่ได้จาก user template -->

            <div class="row w-100">
              <div class="col-3">
                <div class="archer-nav d-flex flex-column heading-a">
                  <template v-for="(item, index) in listUserInfo" :key="index">
                    <a
                      :href="'#' + item.intInfogroupID"
                      class="mb-3 fnt-medium link"
                      :class="{
                        active: item.strInfoGroupNameEN == blnactiveEntry,
                      }"
                      >{{
                        $i18n.locale == "en"
                          ? item.strInfoGroupNameEN
                          : item.strInfoGroupNameTH
                      }}</a
                    >
                  </template>
                </div>
              </div>

              <div class="col-9">
                <Grouped
                  :blnView="blnView"
                  :listUserInfo="listUserInfo"
                  ref="Grouped"
                  @addUserInfo="updateUserData($event)"
                  @blnActiveEntry="activeTagA($event)"
                />
              </div>
            </div>
            <!-- ปุ่ม -->
            <div v-if="blnView == false" class="d-flex mt-5">
              <Button
                @click="UpdateUserInfo()"
                :name="$t('36')"
                class="me-3 btn-primary btn-fix"
              />
              <!-- ปุ่ม OK -->
              <Button
                :name="$t('35')"
                class="btn-cancel btn-fix"
                @click="closeForm()"
              />
              <!-- ปุ่ม Cancel -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//   import Dropdown from "@/components/input/dropdownapidata.vue";
//   import Label from "@/components/label/label.vue";
import Expand from "@/components/form/UserInfo/expand.vue";
import Button from "@/components/button/button.vue";
import Grouped from "./groupmoreinfo.vue";
import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
import apiuserinfo from "@/typescript/urlapi/adminsystem/APIUserInfo";
import AlertPopup from "@/components/alert/alertPopup.vue";
// import group from "@/typescript/group/group";
import Avatar from "@/components/avatar/avatar.vue";
import Enumerable from "linq";
import AltStatus from "@/components/alert/alertStatus.vue";
export default {
  components: {
    //   InputFile,
    Grouped,
    Button,
    AltStatus,
    Expand,
    //   Label,
    //   Dropdown,
    Avatar,
    AlertPopup,
  },
  props: {
    listUserInfo: { type: Array },
    blnView: { type: Boolean, default: true },
    intTemplateID: { type: Number },
    intMemberID: { type: Number },
    usertype: { type: String, defualt: "" },
  },

  emits: ["updatecomplete", "cancel"],
  mounted() {
    let listimage = this.listUserInfo[0].listUserField;
    for (let i = 0; i < listimage.length; i++) {
      // console.log(listimage[i].strDatatype);
      if (listimage[i].strDatatype == 8 || listimage[i].strDatatype == "8") {
        this.listImage = listimage[i];
      }
    }
    setTimeout(() => {
      if (this.listUserInfo.length !== 0) {
        this.SearchListDataTable();
      }
    }, 500);
  },
  data() {
    return {
      listItemsLookup: [],
      listItemsControlField: [],
      listSortData: Array,
      blnHaveHeadling: false,
      strSMSGsuccess: "",
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      blnAltLoadingData: false, //Alt Status logint
      blnactiveEntry: false,
      hover: false, //hover alt
      timerEnabled: false,
      timerCount: 0,
      timerCountHover: 0,
      listImage: {},
      // listUserType: Array,
      // listTemplate: Array,
      // // listUserInfo: Array,
      // blnShowform: false,
      // blnShowUserInfo: false,
      listUserData: Object,
      activeEntry: "",
    };
  },

  methods: {
    goToSection(event) {
      if (this.$refs.Grouped != undefined) {
        this.$refs.Grouped.goToSection(event);
      }
    },
    activeTagA(event) {
      this.blnactiveEntry = event;
      console.log(this.blnactiveEntry);
    },
    updateUserData(data) {
      this.listUserData = data;
      console.log(this.listUserData);
      this.processDataHeading(this.listUserData);
    },
    processDataHeading(data) {
      console.log(this.listSortData);
      Object.values(this.listSortData).forEach((item, child) => {
        item.filter((ele, index) => {
          Object.values(data).filter((datainfo) => {
            // console.log(datainfo);
            if (ele.intUserFieldID == datainfo.intUserFieldID) {
              // console.log("true");
              this.listSortData[child][index].strUserInfoData =
                datainfo.strData;
            }
          });
        });
      });
      // console.log(this.listSortData);
    },
    async UpdateUserInfo() {
      try {
        // console.log(this.expandUserInfoTreeToUserinfoList(this.listUserInfo));
        // var data = await this.expandUserInfoTreeToUserinfoList(
        //   this.listUserInfo
        // );
        console.log(this.listUserInfo);
        // var newdataupdate = [];

        var updateuserinfo = {
          intUserTemplateID: this.intTemplateID,
          userInfoData: this.listUserData,
        };
        let alertCount = 0;
        await this.listUserInfo[0].listUserField.forEach((ele, index) => {
          if (this.listUserData[index].intNotNull == 1) {
            console.log(this.listUserData[index]);
            if (
              this.listUserData[index].strValue == "" &&
              ele.strDataType !== "8"
            ) {
              this.$refs[ele["strFieldNameEN"]][0].alertRequest();
              alertCount++;
            } else if (
              this.listUserData[index].strData == "" &&
              ele.strDataType == "8"
            ) {
              this.$refs[ele["strFieldNameEN"]][0].alertRequest();
              alertCount++;
            }
          }
        });
        console.log(alertCount);
        console.log(this.$refs["Grouped"]);
        if (
          this.$refs["Grouped"].listPropotiesUserField.length == 0 &&
          alertCount == 0
        ) {
          var res = await axios.put(
            urlapi + apiuserinfo.UpdateUserInfoByID + this.intMemberID,
            updateuserinfo,
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PUT",
                "Access-Control-Allow-Headers": "application/*+json",
              },
            }
          );

          console.log(res.data);
          if (res.data.edited == true) {
            //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
            console.log("success");
            this.$emit("updatecomplete", true);
          }
        }
      } catch (error) {
        this.blnAltStatusunsucess = true;

        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        throw new Error(error);
      }
    },

    expandUserInfoTreeToUserinfoList(array) {
      const getNodes = (result, object) => {
        if (Array.isArray(object.listUserField)) {
          const listUserField = object.listUserField.reduce(getNodes, []);

          return listUserField;
        } else {
          result.push({
            intUserFieldID: object.intUserFieldID,
            strData: object.strUserInfoData.toString(),
            intFieldSetID: object.intFieldSetID,
          });
        }

        return result;
      };

      return Object.values(array).slice().reduce(getNodes, []);
    },
    //ค้นหาข้อมูลที่เลือกในครั้งก่อนหน้าาแสดงผลที่ result
    async SearchListDataTable() {
      try {
        // console.log(this.listUserInfo);

        //เรียก function การครั้งหาข้อมูลใน tree list
        // this.listSortData = await this.filter({ ...this.listUserInfo });
        this.listSortData = await { ...this.listUserInfo };

        console.log(this.listSortData);
        if (this.listSortData.length != 0) {
          this.blnHaveHeadling = true;
        }
        // console.log(this.findHeading(1));
        //ค้นหาชื่อมาแสดงผล

        this.$emit("onLevSn", this.result);
      } catch (error) {
        console.log(error);
      }
    },

    findHeading(HeadingLevel) {
      let heading = [];
      Object.values(this.listSortData).forEach((item) => {
        console.log(item);

        Object.values(
          item.listUserField.filter((e) => {
            if (e.intHeadingLevel == HeadingLevel) {
              heading.push(e);
            }
          })
        );
      });
      // console.log(heading);
      heading = Enumerable.from(heading)
        .orderBy((r) => r.intHeadingOrder)
        .toArray();
      var result = "";
      Object.values(heading).forEach((e) => {
        if (e.intFieldType == 1) {
          this.getUserControlField(e.strRelation, e.intUserFieldID);
        } else if (e.intFieldType == 4) {
          this.getUserLookupField(e.intUserFieldID);
        }
      });
      Object.values(heading).forEach((e) => {
        if (e.intFieldType == 1) {
          // console.log(this.listItemsControlField[e.intUserFieldID]);
          if (this.listItemsControlField[e.intUserFieldID] !== undefined) {
            let userfield = this.listItemsControlField[e.intUserFieldID].filter(
              (ele) => {
                return ele.strID == e.strUserInfoData;
              }
            );
            // console.log(userfield);
            result +=
              this.$i18n.locale == "en"
                ? userfield[0].strNameEN + " "
                : userfield[0].strNameTH + " ";
          }
        } else if (e.intFieldType == 4) {
          if (this.listItemsLookup[e.intUserFieldID] !== undefined) {
            let userfield = this.listItemsLookup[e.intUserFieldID].filter(
              (ele) => {
                return ele.strID == e.strUserInfoData;
              }
            );
            // console.log(userfield);
            result +=
              this.$i18n.locale == "en"
                ? userfield[0].strNameEN + " "
                : userfield[0].strNameTH + " ";
          }
        } else result += e.strUserInfoData + "  ";
      });

      return result;
    },
    async getUserLookupField(intUserFieldID) {
      try {
        if (this.listItemsLookup[intUserFieldID] == undefined) {
          var res = await axios.get(
            urlapi + "/v1/api/AdsUserTemplate/LookupList/" + intUserFieldID
          );
          // console.log(res);
          this.listItemsLookup[intUserFieldID] = res.data.data;
          // console.log(this.listItemsLookup[intUserFieldID]);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getUserControlField(strRelation, intUserFieldID) {
      try {
        if (this.listItemsControlField[intUserFieldID] == undefined) {
          var res = await axios.get(
            urlapi +
              "/v1/api/AdsUserTemplate/GetControlFieldData/" +
              strRelation
          );
          // console.log(res);
          this.listItemsControlField[intUserFieldID] = res.data.data;
          // console.log(this.listItemsControlField[intUserFieldID]);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //function การ loop ค้นหาข้อมูลแบบ tree list
    async filter(array) {
      const getNodes = (result, object) => {
        if (Array.isArray(object.listUserField)) {
          const listUserField = object.listUserField.reduce(getNodes, []);

          if (listUserField.length) {
            result.push(listUserField);
            return result;
          }
        }
        if (this.filterSelected(object)) {
          result.push(object);
          return result;
        }

        return result;
      };

      return Object.values(array).slice().reduce(getNodes, []);
    },
    expand() {
      if (this.$refs.Grouped != null) {
        this.$refs.Grouped.onExpand();
      }
    },

    //ค้นหาข้อมูลที่มีค่าตรงกับที่กำหนด
    filterSelected(item) {
      return item.intHeadingLevel != 0;
    },
    closeForm() {
      console.log("click cancel from viewuser");
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.inputfile {
  height: 100px !important;
  width: 88px !important;
}
.link {
  text-decoration: none;
  color: $black;
  &:hover {
    color: $black;
    text-decoration: underline;
  }
  &.active {
    color: $warning;
  }
}
.heading {
  position: sticky;
  background-color: $white;
  z-index: 1;
  top: -8px;
}
.heading-a {
  position: sticky;
  background-color: $white;
  z-index: 1;
  top: 0%;
}
.form {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  max-height: 525px;
  width: 101%;
}
</style>