<template>
  <div class="ms-9 mb-5 form-lg bg-white rounded-3 shadow-sm">
    <Viewuser
      :blnView="true"
      v-if="blnShowUserInfo == true"
      :listUserInfo="listUserInfo[0].listUserInfo"
      :usertype="this.$route.query.strUserTypeName"
    />
  </div>
</template>

<script>
import Viewuser from "@/components/form/UserInfo/moreinfo.vue";
import apiuserinfo from "@/typescript/urlapi/adminsystem/APIUserInfo";
import urlapi from "@/typescript/urlapi/url";
import axios from "axios";
import Enumerable from "linq";
import AdsInventories from "@/typescript/inventory";
export default {
  components: {
    Viewuser,
  },
  props: ["memberID", "TemplateID"],

  mounted() {
    // console.log(this.memberID);
    // console.log(this.$route.query.TemplateID);
    // console.log(this.$route.query.strUserTypeName); //จากการคลิก moreinfo
    this.getUserInfoByID(this.memberID);
  },
  data() {
    return {
      listUserInfo: [],
      blnShowUserInfo: false,
      userinfo: "",
    };
  },
  methods: {
    async getUserInfoByID(ID) {
      try {
        // console.log(
        //   urlapi +
        //     apiuserinfo.GetUserInfoByID +
        //     AdsInventories.userinfo.intMemberID +
        //     "?UserInfoID=" +
        //     ID
        // );
        var res = await axios.get(
          urlapi +
            apiuserinfo.GetUserInfoByID +
            AdsInventories.userinfo.intMemberID +
            "?UserInfoID=" +
            ID
        );
        this.listUserInfo = { ...res.data.data };
        await this.listUserInfo[0].listUserInfo.forEach((child) => {
          // console.log(JSON.stringify(dummy[0]));
          child.listUserField.forEach((ele, index) => {
            if (ele.intFieldSetID != 0) {
              // console.log(JSON.stringify(dummy[0]));
              let data = {
                strID: ele.intFieldSetID,
                strUserFieldNameEN: ele.strUserFieldSetNameEN,
                strUserFieldNameTH: ele.strUserFieldSetNameEN,
                intUserInfoOrder: ele.intUserInfoOrder,

                intFieldSetID: 0,
                intFieldType: 1,
                items: [],
              };
              if (this.listUserInfo[0].listUserInfo[index] != undefined) {
                this.listUserInfo[0].listUserInfo[index].listUserField.forEach(
                  (field) => {
                    if (field.intFieldSetID == ele.intFieldSetID) {
                      data.items.push(field);

                      this.listUserInfo[0].listUserInfo[index].listUserField =
                        this.listUserInfo[0].listUserInfo[
                          index
                        ].listUserField.filter((childindex) => {
                          return (
                            field.intUserFieldID != childindex.intUserFieldID
                          );
                        });
                    }
                    // else {
                    //   field.push({ items: [] });
                    // }
                  }
                );
              }

              data.items = Enumerable.from(data.items)
                .orderByDescending((r) => r.intFieldSetOrder)
                .toArray();
              data.items = data.items.reverse();

              if (data.items.length != 0) {
                this.listUserInfo[0].listUserInfo[index].listUserField.push(
                  data
                );
              }
            }
            if (this.listUserInfo[0].listUserInfo[index] != undefined) {
              this.listUserInfo[0].listUserInfo[index].listUserField =
                Enumerable.from(
                  this.listUserInfo[0].listUserInfo[index].listUserField
                )
                  .orderByDescending((r) => r.intUserInfoOrder)
                  .toArray();
              this.listUserInfo[0].listUserInfo[index].listUserField =
                this.listUserInfo[0].listUserInfo[
                  index
                ].listUserField.reverse();
            }
          });
        });
        //prefix
        Object.values(res.data.data[0].listUserInfo[0].listUserField).filter(
          (ele) => {
            if (ele.intUserFieldID == 1) {
              this.userinfo += ele.strUserInfoData + " ";
            }
          }
        );
        // firstname
        Object.values(res.data.data[0].listUserInfo[0].listUserField).filter(
          (ele) => {
            if (ele.intUserFieldID == 32) {
              this.userinfo += ele.strUserInfoData + " ";
            }
          }
        );
        //lastname
        Object.values(res.data.data[0].listUserInfo[0].listUserField).filter(
          (ele) => {
            if (ele.intUserFieldID == 33) {
              this.userinfo += ele.strUserInfoData;
            }
          }
        );

        document.title = this.userinfo;

        if (this.listUserInfo.length !== 0) {
          this.blnShowUserInfo = true;
        }
        // console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>